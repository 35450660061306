@import '../../styles/customMediaQueries.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0px; /* Default padding for mobile view */
}

@media (min-width: 768px) {
  /* Define a breakpoint for desktop view */
  .root {
    padding: 20px; /* Padding for desktop view */
  }
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}
.modalContainerOrder {
  composes: marketplaceModalInMobileBaseStyles from global;
  flex-direction: unset;
  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
.modalContentOrder {
  width: 100%;
  max-height: 96%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
